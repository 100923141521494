<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-52 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <div class="blur-box">
      <p class="text-center pt-4 pb-10 font-medium text-white">اطلاعات خود را وارد کنید</p>
      <label class="block">
        <span class="text-white opacity-50 text-sm">نام</span>
        <input
            type="text"
            class="mt-0 block text-white w-full py-2 px-0.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
            v-model="firstName"
        >
      </label>
      <label class="block mt-7">
        <span class="text-white opacity-50 text-sm">نام خانوادگی</span>
        <input
            type="text"
            class="mt-0 block text-white w-full py-2 px-0.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
            v-model="lastName"
        >
      </label>
      <a @click.prevent="createUser" class="btn-custom mt-11">ثبت اطلاعات</a>
    </div>
  </div>
</template>
<script>
import axios from "../plugins/axios";
export default {
  name: "UserCreate",

  data(){
    return{
      firstName : '',
      lastName : '',
      verifyId : this.$store.state.auth.auth.token
    }
  },
  methods :{
    createUser(){
      if (this.firstName.length <= 3 && this.lastName <= 3){
        this.$toast.error('حداقل تعداد کارکتر باید 3 حروف باشد')
      }else{
        axios.post('/users',{
          verify_id : this.verifyId,
          first_name : this.firstName,
          last_name : this.lastName,
          type : 'user',
        })
        .then(({ data }) =>{
          if (data.status){
            this.$toast.success('ثبت نام شما با موفقیت انجام شد')
            localStorage.setItem('user-token',data.data.token)
            this.$store.commit('auth/SET_USER_DATA' , data.data.user)
            axios.get('/users').then(({data})=>{
              localStorage.setItem('user', JSON.stringify(data.data))
              this.$router.push({name : 'HomeCustomer'})
            })
          }
            })
            .catch(function (err){
              this.$toast.error(err.message)
            })
      }

    },
  },
  computed :{

  },
  created() {

  }
}
</script>

<style scoped>

</style>